import React, { useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

// markup
const DndGrid = () => {
  const photos = [1, 2, 3, 4, 5];
  const [items, setItems] = useState(photos);
  //console.log("this is the color " + JSON.stringify(photos));
  //console.log(photos.photos);
  // supply your own state
  //const [items, setItems] = useState(photos.photos);

  // target id will only be set if dragging from one dropzone to another.
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(items, sourceIndex, targetIndex);
    console.log("Next State :" + nextState);
    setItems(nextState);
  }

  return (
    <>
      <h1>lets go</h1>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={4}
          rowHeight={100}
          style={{ height: "400px" }}
        >
          {items.map((item) => (
            <GridItem key={item}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {item}
              </div>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </>
  );
};

export default DndGrid;
