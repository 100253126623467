import React, { useEffect, useState } from "react";
import { CallAxios } from "../../components/axios/axiosFunctions";
import { Link } from "gatsby";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DndGrid from "../../components/dragAndDrop/dndgrid";

// markup
const CarFile = () => {
  const [MyMake, setMyMake] = useState();
  const [MyYear, setMyYear] = useState();
  const [MyModel, setMyModel] = useState();
  const [MyKm, setMyKm] = useState();
  const [MyPrice, setMyPrice] = useState();
  //Mondal
  const [showMondal, setShowMondal] = useState(false);
  const handleClose = () => setShowMondal(false);
  const handleShow = () => setShowMondal(true);
  //Photos
  const [myTablePhotos, setMyTablePhotos] = useState([]);
  //GetCarData
  async function getCarData() {
    let x = await CallAxios(
      "https://dakaramerica.com/scripts/updateCar.php",
      "get",
      localStorage.getItem("selectedCar")
    );
    setMyMake(x[0].carmake);
    setMyYear(x[0].caryear);
    setMyModel(x[0].carmodel);
    setMyKm(x[0].carkm);
    setMyPrice(x[0].carprice);
    getCarPhotos(x[0].carphotos);
  }

  //Get Car Photos
  async function getCarPhotos(photoID) {
    console.log("This is the ID passed : " + photoID);
    let y = await CallAxios(
      "https://dakaramerica.com/scripts/getPhotos.php",
      "get",
      photoID
    );
    setMyTablePhotos(y);
    console.log("This is my given array :" + y);
    console.log("Photos :" + y[0].imagename);
  }

  //Handles the drag and drop
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    console.log("INSERT IN THIS PLACE : " + result.source.index);
    console.log("INSERT THIS INDEX :" + result.destination.index);
    console.log(
      "Change This orderIndex : " +
        myTablePhotos[result.source.index].orderIndex
    );
    myTablePhotos[result.source.index].orderIndex = result.destination.index;

    const items = Array.from(myTablePhotos);
    console.log("This are the items: " + JSON.stringify(items));
    const [reorderedItem] = items.splice(result.source.index, 1);

    console.log("insert here : " + items);
    console.log("This is the reorder item : " + JSON.stringify(reorderedItem));
    items.splice(result.destination.index, 0, reorderedItem);
    console.log("This is the reorder item : " + JSON.stringify(reorderedItem));
    setMyTablePhotos(items);
    console.log(myTablePhotos);
    console.log(result);
  }

  //Deletes photo from db and hideit on react

  //shows the ID of selected car
  function displayCarInStorage() {
    console.log(localStorage.getItem("selectedCar"));
  }

  useEffect(() => {
    getCarData();
    displayCarInStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h1>Modifier</h1>
      <Modal show={showMondal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sortir sans enregistrer?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Link to="../">
            <Button variant="danger" onClick={handleClose}>
              Je ne veux pas enregistrer
            </Button>
          </Link>

          <Button variant="primary" onClick={handleClose}>
            Revenir
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="danger" onClick={handleShow}>
        Annuler et revenir en arriere
      </Button>
      <Form class="mt-3">
        <Form.Label>Make :</Form.Label>
        <Form.Control
          type="text"
          value={MyMake}
          onChange={(e) => setMyYear(e.target.value)}
        />
        <Form.Label>Model :</Form.Label>
        <Form.Control
          type="text"
          value={MyModel}
          onChange={(e) => setMyYear(e.target.value)}
        />
        <Form.Label>Year :</Form.Label>
        <Form.Control
          type="text"
          value={MyYear}
          onChange={(e) => setMyYear(e.target.value)}
        />
        <Form.Label>Km :</Form.Label>
        <Form.Control
          type="text"
          value={MyKm}
          onChange={(e) => setMyYear(e.target.value)}
        />
        <Form.Label>Price :</Form.Label>
        <Form.Control
          type="text"
          value={MyPrice}
          onChange={(e) => setMyYear(e.target.value)}
        />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button variant="success" size="lg mt-3" type="submit">
            Enregistrer
          </Button>
        </div>
      </Form>
      <h3>Dragable Photos</h3>
      <div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="photos" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  background: "red",
                  border: "1px solid black",
                }}
              >
                {myTablePhotos.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.imagename}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <img
                          src={
                            "https://dakaramerica.com/images/" + item.imagename
                          }
                          width="200"
                          alt=""
                        />
                        <Button variant="danger">Delete</Button>
                        <p>{item.imagename}</p>
                        <p>Photo index : {item.orderIndex}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {provided.placeholder}
                {provided.placeholder}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <DndGrid></DndGrid>
    </Container>
  );
};

export default CarFile;
